import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ReactComponent as Flecha } from "../assets/flecha.svg";
import { ReactComponent as Reloj } from "../assets/reloj.svg";
import { ReactComponent as QR } from "../assets/qr.svg";
import { ReactComponent as Like } from "../assets/like.svg";

const CobrarConPix = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const fadeLeftToRight = {
    hidden: { opacity: 0, x: -50 }, // Empieza invisible y desplazado hacia la izquierda
    visible: { opacity: 1, x: 0 }, // Aparece y se mueve a su posición original
  };

  const buttonWidth = {
    hidden: { width: 0 },
    visible: {
      width: window.innerWidth <= 1024 ? "13.5625rem" : "34.5625rem",
    },
  };

  return (
    <div
      ref={ref}
      className={`
        text-white
        mb-[6rem]
        xl-large:mt-[190px]
        xl-small:mt-[190px]
        mobile-base:mt-[130px]
        mobile-base:mb-[3rem]
        xl-small:mb-[8rem]
        xl-large:mb-[14rem]

        /* Centrado y ancho máximo */
        xl-small:max-w-[1348px]
        xl-large:max-w-[1648px]
        lg:max-w-[648px]
        lg:mt-[12rem]
        w-full
        mx-auto
      `}
    >
      <div
        className="
          px-[4rem]
          mobile-base:px-[1rem]
          xl-small:px-[4rem]
          xl-large:px-[12rem]
        "
      >
        {/* Botón animado */}
        <motion.div
          id="how-to"
          className="
            flex items-center justify-center
            xl-large:h-[4.125rem]
            xl-small:h-[4.125rem]
            mobile-base:h-[2rem]
            bg-[#28AD7E]
            rounded-full
            opacity-100
            mb-6
            mobile-base:mb-3
            xl-small:ml-[3rem]
            md:ml-[6rem]
            xl-large:ml-[-8rem]
            xl-small:mb-[1.75rem]
            xl-large:mb-[1.75rem]
            mobile-base:ml-[1rem]
            lg:ml-[1rem]
            lg:mb-[1.75rem]
          "
          variants={buttonWidth}
          initial="hidden"
          animate={controls}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <motion.span
            className="
              text-white
              font-dupletRounded
              font-bold
              text-[39.64px]
              mobile-base:text-[1rem]
              lg:text-[1rem]
              xl-small:text-[39.64px]
              xl-large:text-[39.64px]
            "
            variants={fadeLeftToRight}
            initial="hidden"
            animate={controls}
            transition={{ duration: 1.5, ease: "easeInOut", delay: 0.5 }}
          >
            ¿Cómo cobrar con Pix?
          </motion.span>
        </motion.div>

        {/* Contenedor de pasos */}
        <div
          className="
            [&>*]:mb-[1.625rem]
            mobile-base:[&>*]:mb-[0.75rem]
            xl-small:[&>*]:mb-[1rem]
            xl-large:[&>*]:mb-[2rem]
            flex flex-col items-center
          "
        >
          {/* Paso 1 */}
          <motion.div
            className="
              flex items-center
              xl-large:rounded-[6.25rem]
              xl-small:rounded-[6.25rem]
              mobile-base:rounded-[13.89px]
              w-[93.75rem]
              mobile-base:w-[335px]
              md:w-[495px]
              xl-small:w-[70rem]
              xl-large:w-[100rem]
              h-[12.25rem]
              mobile-base:h-[112px]
              xl-small:h-[10rem]
              xl-large:h-[14rem]
            "
            style={{
              background:
                "linear-gradient(270deg, #7A4AFF 55.5%, rgba(122, 74, 255, 0) 93.5%)",
            }}
            variants={fadeInUp}
            initial="hidden"
            animate={controls}
            transition={{ duration: 1, ease: "easeOut", delay: 0.5 }}
          >
            <div
              className="
                flex items-center justify-center
                w-16
                mobile-base:w-8
                xl-small:w-12
                xl-large:w-20
                h-16
                mobile-base:h-8
                xl-small:h-12
                xl-large:h-20
                rounded-full
                ml-[3.875rem]
                mobile-base:ml-[1rem]
                xl-small:ml-[2rem]
                xl-large:ml-[5rem]
                mr-[5rem]
                mobile-base:mr-[1rem]
                xl-small:mr-[3rem]
                xl-large:mr-[6rem]
              "
            >
              <span
                className="
                  text-white
                  opacity-40
                  font-blatant
                  font-bold
                  text-[9.3125rem]
                  mobile-base:text-[3rem]
                  xl-small:text-[7rem]
                  xl-large:text-[11rem]
                "
              >
                1
              </span>
            </div>

            <div className="flex mobile-base:flex-col md:flex-row xl-small:flex-row">
              <div
                className="
                  flex items-center
                  mobile-base:w-auto
                  mobile-base:mb-[5px]
                  xl-small:w-[28rem]
                  xl-large:w-[37rem]
                  h-[7rem]
                  mobile-base:h-auto
                  xl-small:h-[5rem]
                  xl-large:h-[8rem]
                "
              >
                <h3
                  className="
                    text-white
                    font-bold
                    opacity-100
                    mobile-base:text-[22px]
                    xl-small:text-[3rem]
                    xl-large:text-[3.96875rem]
                    leading-[3.5625rem]
                    mobile-base:leading-[20px]
                    xl-small:leading-[2.5rem]
                    xl-large:leading-[4rem]
                  "
                  style={{ fontFamily: "'Blatant Bold', sans-serif" }}
                >
                  REGISTRATE <br /> EN MINUTOS
                </h3>
              </div>
              <div
                className="
                  flex items-center
                  mobile-base:w-auto
                  md:ml-[55px]
                  xl-small:w-[20rem]
                  xl-large:w-[30rem]
                  h-[7rem]
                  mobile-base:h-auto
                  xl-small:h-[5rem]
                  xl-large:h-[8rem]
                "
              >
                <p
                  className="
                    text-white
                    opacity-100
                    font-normal
                    mobile-base:text-[14px]
                    xl-small:text-[1.5rem]
                    xl-large:text-[2.3125rem]
                    leading-[2.5rem]
                    mobile-base:leading-[14px]
                    xl-small:leading-[2rem]
                    xl-large:leading-[3rem]
                  "
                  style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
                >
                  Creá tu cuenta gratis <br /> para vos o tu empresa
                </p>
              </div>
            </div>

            {/* Flecha + Reloj */}
            <motion.div
              className="
                hidden
                md:hidden
                xl-large:block
                xl-small:block
                sm:flex
                items-center justify-center
                relative
                xl-small:right-[10rem]
                xl-large:right-[12.8rem]
                xl-small:top-[-3.5rem]
                xl-large:top-[-3.3rem]
              "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 2.3 }}
            >
              <Flecha alt="Flecha hacia abajo" />
            </motion.div>

            <div
              className="
                flex items-center justify-center
                mobile-base:ml-[3.5rem]
                xl-large:w-[90px]
                xl-small:w-[90px]
                mobile-base:w-[40px]
                xl-small:ml-[-10rem]
                xl-large:ml-[-8rem]
                mobile-base:mr-[1.5rem]
                xl-small:mr-[3rem]
                xl-large:mr-[5.5rem]
              "
            >
              <Reloj alt="Icono de reloj" />
            </div>
          </motion.div>

          {/* Paso 2 */}
          <motion.div
            className="
              flex items-center
              xl-large:rounded-[6.25rem]
              xl-small:rounded-[6.25rem]
              mobile-base:rounded-[13.89px]
              w-[93.75rem]
              mobile-base:w-[335px]
              md:w-[495px]
              xl-small:w-[70rem]
              xl-large:w-[100rem]
              h-[12.25rem]
              mobile-base:h-[112px]
              xl-small:h-[10rem]
              xl-large:h-[14rem]
            "
            style={{
              background:
                "linear-gradient(270deg, #7A4AFF 55.5%, rgba(122, 74, 255, 0) 93.5%)",
            }}
            variants={fadeInUp}
            initial="hidden"
            animate={controls}
            transition={{ duration: 1, ease: "easeOut", delay: 1 }}
          >
            <div
              className="
                flex items-center justify-center
                w-16
                mobile-base:w-8
                xl-small:w-12
                xl-large:w-20
                h-16
                mobile-base:h-8
                xl-small:h-12
                xl-large:h-20
                rounded-full
                ml-[3.875rem]
                mobile-base:ml-[1rem]
                xl-small:ml-[2rem]
                xl-large:ml-[5rem]
                mr-[5rem]
                mobile-base:mr-[1rem]
                xl-small:mr-[3rem]
                xl-large:mr-[6rem]
              "
            >
              <span
                className="
                  text-white
                  opacity-40
                  font-blatant
                  font-bold
                  text-[9.3125rem]
                  mobile-base:text-[3rem]
                  xl-small:text-[7rem]
                  xl-large:text-[11rem]
                "
              >
                2
              </span>
            </div>

            <div className="flex mobile-base:flex-col md:flex-row xl-small:flex-row">
              <div
                className="
                  flex items-center
                  mobile-base:w-auto
                  mobile-base:mb-[5px]
                  xl-small:w-[28rem]
                  xl-large:w-[37rem]
                  h-[7rem]
                  mobile-base:h-auto
                  xl-small:h-[5rem]
                  xl-large:h-[8rem]
                "
              >
                <h3
                  className="
                    text-white
                    font-bold
                    opacity-100
                    mobile-base:text-[22px]
                    xl-small:text-[3rem]
                    xl-large:text-[3.96875rem]
                    leading-[3.5625rem]
                    mobile-base:leading-[20px]
                    xl-small:leading-[2.5rem]
                    xl-large:leading-[4rem]
                  "
                  style={{ fontFamily: "'Blatant Bold', sans-serif" }}
                >
                  GENERÁ TU QR <br /> O LINK DE PAGO
                </h3>
              </div>
              <div
                className="
                  flex items-center
                  mobile-base:w-auto
                  md:ml-[35px]
                  xl-small:w-[20rem]
                  xl-large:w-[30rem]
                  h-[7rem]
                  mobile-base:h-auto
                  xl-small:h-[5rem]
                  xl-large:h-[8rem]
                "
              >
                <p
                  className="
                    text-white
                    opacity-100
                    font-normal
                    mobile-base:text-[14px]
                    xl-small:text-[1.5rem]
                    xl-large:text-[2.3125rem]
                    leading-[2.5rem]
                    mobile-base:leading-[14px]
                    xl-small:leading-[2rem]
                    xl-large:leading-[3rem]
                  "
                  style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
                >
                  Y compartilo para pagar <br /> presencial o a distancia
                </p>
              </div>
            </div>

            <div
              className="
                flex items-center justify-center
                mobile-base:ml-[2rem]
                xl-large:w-[90px]
                xl-small:w-[90px]
                mobile-base:w-[40px]
                xl-small:ml-[2.5rem]
                xl-large:ml-[5rem]
                mobile-base:mr-[1.5rem]
                xl-small:mr-[3rem]
                xl-large:mr-[5.5rem]
              "
            >
              <QR alt="Código QR para realizar el pago" />
            </div>
          </motion.div>

          {/* Paso 3 */}
          <motion.div
            className="
              flex items-center
              xl-large:rounded-[6.25rem]
              xl-small:rounded-[6.25rem]
              mobile-base:rounded-[13.89px]
              w-[93.75rem]
              mobile-base:w-[335px]
              md:w-[495px]
              xl-small:w-[70rem]
              xl-large:w-[100rem]
              h-[12.25rem]
              mobile-base:h-[112px]
              xl-small:h-[10rem]
              xl-large:h-[14rem]
            "
            style={{
              background:
                "linear-gradient(270deg, #7A4AFF 55.5%, rgba(122, 74, 255, 0) 93.5%)",
            }}
            variants={fadeInUp}
            initial="hidden"
            animate={controls}
            transition={{ duration: 1, ease: "easeOut", delay: 1.5 }}
          >
            <div
              className="
                flex items-center justify-center
                w-16
                mobile-base:w-8
                xl-small:w-12
                xl-large:w-20
                h-16
                mobile-base:h-8
                xl-small:h-12
                xl-large:h-20
                rounded-full
                ml-[3.875rem]
                mobile-base:ml-[1rem]
                xl-small:ml-[2rem]
                xl-large:ml-[5rem]
                mr-[5rem]
                mobile-base:mr-[1rem]
                xl-small:mr-[3rem]
                xl-large:mr-[6rem]
              "
            >
              <span
                className="
                  text-white
                  opacity-40
                  font-blatant
                  font-bold
                  text-[9.3125rem]
                  mobile-base:text-[3rem]
                  xl-small:text-[7rem]
                  xl-large:text-[11rem]
                "
              >
                3
              </span>
            </div>

            <div className="flex mobile-base:flex-col md:flex-row xl-small:flex-row">
              <div
                className="
                  flex items-center
                  mobile-base:w-auto
                  mobile-base:mb-[5px]
                  xl-small:w-[28rem]
                  xl-large:w-[37rem]
                  h-[7rem]
                  mobile-base:h-auto
                  xl-small:h-[5rem]
                  xl-large:h-[8rem]
                "
              >
                <h3
                  className="
                    text-white
                    font-bold
                    opacity-100
                    mobile-base:text-[22px]
                    xl-small:text-[3rem]
                    xl-large:text-[3.96875rem]
                    leading-[3.5625rem]
                    mobile-base:leading-[20px]
                    xl-small:leading-[2.5rem]
                    xl-large:leading-[4rem]
                  "
                  style={{ fontFamily: "'Blatant Bold', sans-serif" }}
                >
                  RECIBÍ LA PLATA <br /> AL INSTANTE
                </h3>
              </div>
              <div
                className="
                  flex items-center
                  mobile-base:w-auto
                  md:ml-[35px]
                  xl-small:w-[20rem]
                  xl-large:w-[30rem]
                  h-[7rem]
                  mobile-base:h-auto
                  xl-small:h-[5rem]
                  xl-large:h-[8rem]
                "
              >
                <p
                  className="
                    text-white
                    opacity-100
                    font-normal
                    mobile-base:text-[14px]
                    xl-small:text-[1.5rem]
                    xl-large:text-[2.3125rem]
                    leading-[2.5rem]
                    mobile-base:leading-[14px]
                    xl-small:leading-[2rem]
                    xl-large:leading-[3rem]
                  "
                  style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
                >
                  Apenas se hace el pago <br /> la plata está en tu cuenta
                </p>
              </div>
            </div>

            <div
              className="
                flex items-center justify-center
                mobile-base:ml-[2rem]
                xl-large:w-[90px]
                xl-small:w-[90px]
                mobile-base:w-[40px]
                xl-small:ml-[2.5rem]
                xl-large:ml-[5rem]
                mobile-base:mr-[1.5rem]
                xl-small:mr-[3rem]
                xl-large:mr-[5.5rem]
              "
            >
              <Like alt="Icono de like" />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default CobrarConPix;
