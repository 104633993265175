import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ReactComponent as LogoContacto } from "../assets/locoContacto.svg";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    timestamp: new Date().toISOString(),
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //const mailtoLink = `mailto:info@uvix.io?subject=Contacto desde el formulario&body=Nombre: ${formData.name}%0AEmail: ${formData.email}%0ATeléfono: ${formData.phone}%0AMensaje: ${formData.message}`;
    //window.location.href = mailtoLink;
    setLoading(true);
    fetch("https://hooks.zapier.com/hooks/catch/20018427/2fanui7/", {
      method: "POST",
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          setSent(true);
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);

  return (
    <div
      ref={ref}
      id="contact"
      className={`
        /* Contenedor principal */
        xl-small:max-w-[1348px]
        xl-large:max-w-[1648px]
        w-full
        mx-auto
        
        /* Paddings laterales fluidos */
        px-6                /* Mobile base: px = 0 */
        sm:px-10            /* Small y en adelante: px = 10 */
        md:px-10
        lg:px-[4.5rem]
        xl-small:px-[8.5rem]
        mobile-base:px-0
        
        mb-3
        md:mb-6
        lg:mb-[191px]
        flex justify-center items-center
      `}
    >
      <motion.div
        className="
          w-full
          bg-white bg-opacity-20
          rounded-none lg:rounded-[2.5rem]
          flex flex-col lg:flex-row
          mobile-base:px-5
          mobile-base:py-10
          xl-small:py-3
          lg:px-[8.5rem]
          xl-small:px-[6.5rem]
        "
        initial={{ opacity: 0, y: 100 }}
        animate={controls}
        transition={{ delay: 0.5, duration: 1 }}
      >
        {/* Columna Izquierda: Logo y Texto */}
        <motion.div
          className="
            text-white
            mb-1 lg:mb-0
            w-full lg:w-[32.1875rem]
            h-auto lg:h-[31.625rem]
            flex flex-col justify-center
            mt-2 lg:mt-[4.375rem]
          "
          initial={{ opacity: 0, y: 100 }}
          animate={controls}
          transition={{ delay: 1, duration: 1 }}
        >
          <div className="xl-large:mb-[1.0625rem] xl-small:mb-[1.0625rem]">
            <LogoContacto
              className="
                mobile-base:w-[5rem]
                md:w-[5rem]
                xl-small:w-[10.5625rem]
                xl-large:w-[10.5625rem]
              "
              title="Logo de contacto UVIX"
            />
          </div>

          <p
            className="
              text-white
              text-lg
              mb-[3.0625rem]
              mobile-base:mb-[1.0625rem]
              xl-large:text-[2.5rem]
              xl-large:leading-[3.0625rem]
              xl-small:leading-[2.0625rem]
              lg:font-[400]
              xl-small:text-[2rem]
            "
            style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
          >
            Somos un equipo que trabaja para{" "}
            <span className="font-bold">
              facilitar los cobros internacionales
            </span>
            .
          </p>

          <p
            className="
              text-lg
              text-white
              xl-large:text-[2.5rem]
              xl-large:leading-[3.0625rem]
              xl-small:leading-[2.0625rem]
              lg:font-[400]
              xl-small:text-[2rem]
            "
            style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
          >
            Nos especializamos en ofrecer soluciones tecnológicas e innovadoras
            para tu negocio.
          </p>
        </motion.div>

        {/* Columna Derecha: Formulario */}
        <div
          className="
            bg-transparent
            rounded-none lg:rounded-lg
            w-full lg:w-[49.6875rem]
            h-auto lg:h-[32.4375rem]
            flex flex-col justify-center
            lg:ml-[3.375rem]
            mt-2 lg:mt-[4.375rem]
            mb-2 lg:mb-[6rem]
          "
        >
          <motion.h2
            className="
              text-white
              text-[1.25rem]
              lg:text-[1.5625rem]
              mb-0
              font-bold
            "
            initial={{ opacity: 0, y: 100 }}
            animate={controls}
            transition={{ delay: 1.5, duration: 1 }}
          >
            Contáctanos
          </motion.h2>

          <motion.form
            className="space-y-4"
            onSubmit={handleSubmit}
            initial={{ opacity: 0, y: 100 }}
            animate={controls}
            transition={{ delay: 1.6, duration: 1 }}
          >
            {/* Nombre */}
            <label htmlFor="name" className="sr-only">
              Nombre de la empresa o persona
            </label>
            <motion.input
              id="name"
              type="text"
              name="name"
              placeholder="Nombre de la empresa o persona"
              value={formData.name}
              onChange={handleChange}
              className="
                w-full
                text-white
                rounded-lg
                focus:outline-none focus:ring-2
                text-[1rem]
                lg:text-[1.5625rem]
                font-normal
                bg-[#122F30]
                hover:bg-[#0E2425]
                border-none
                placeholder:text-white
                placeholder:text-opacity-60
                placeholder:font-normal
                placeholder:text-[1rem]
                lg:placeholder:text-[1.5625rem]
                pl-[1rem] py-[1rem]
              "
              initial={{ opacity: 0, y: 100 }}
              animate={controls}
              transition={{ delay: 1.7, duration: 1 }}
            />

            {/* Email */}
            <label htmlFor="email" className="sr-only">
              Correo
            </label>
            <motion.input
              id="email"
              type="email"
              name="email"
              placeholder="Correo"
              value={formData.email}
              onChange={handleChange}
              className="
                w-full
                text-white
                rounded-lg
                focus:outline-none focus:ring-2
                text-[1rem]
                lg:text-[1.5625rem]
                font-normal
                bg-[#122F30]
                hover:bg-[#0E2425]
                border-none
                placeholder:text-white
                placeholder:text-opacity-60
                placeholder:font-normal
                placeholder:text-[1rem]
                lg:placeholder:text-[1.5625rem]
                pl-[1rem] py-[1rem]
              "
              initial={{ opacity: 0, y: 100 }}
              animate={controls}
              transition={{ delay: 1.8, duration: 1 }}
            />

            {/* Teléfono */}
            <label htmlFor="phone" className="sr-only">
              Teléfono
            </label>
            <motion.input
              id="phone"
              type="tel"
              name="phone"
              placeholder="Teléfono"
              value={formData.phone}
              onChange={handleChange}
              className="
                w-full
                text-white
                rounded-lg
                focus:outline-none focus:ring-2
                text-[1rem]
                lg:text-[1.5625rem]
                font-normal
                bg-[#122F30]
                hover:bg-[#0E2425]
                border-none
                placeholder:text-white
                placeholder:text-opacity-60
                placeholder:font-normal
                placeholder:text-[1rem]
                lg:placeholder:text-[1.5625rem]
                pl-[1rem] py-[1rem]
              "
              initial={{ opacity: 0, y: 100 }}
              animate={controls}
              transition={{ delay: 1.9, duration: 1 }}
            />

            {/* Mensaje */}
            <label htmlFor="message" className="sr-only">
              Mensaje
            </label>
            <motion.textarea
              id="message"
              name="message"
              placeholder="Mensaje"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              className="
                w-full
                text-white
                rounded-lg
                focus:outline-none focus:ring-2
                text-[1rem]
                lg:text-[1.5625rem]
                font-normal
                bg-[#122F30]
                hover:bg-[#0E2425]
                border-none
                placeholder:text-white
                placeholder:text-opacity-60
                placeholder:font-normal
                placeholder:text-[1rem]
                lg:placeholder:text-[1.5625rem]
                pl-[1rem] py-[1rem]
              "
              initial={{ opacity: 0, y: 100 }}
              animate={controls}
              transition={{ delay: 2, duration: 1 }}
            ></motion.textarea>

            {/* Botón Enviar */}
            <div className="flex items-center gap-4">
              <motion.button
                type="submit"
                aria-label="Enviar formulario de contacto"
                className="
                bg-[#28AD7E]
                text-white
                font-bold
                rounded-[6.25rem]
                hover:bg-[#1D5C46]
                transition
                xl-large:w-[11.625rem]
                xl-large:h-[3.75rem]
                xl-small:w-[11.625rem]
                xl-small:h-[3.75rem]
                mobile-base:w-[119px]
                mobile-base:h-[40px]
                mobile-base:text-[20px]
                xl-large:text-[26px]
                xl-small:text-[26px]
              "
                initial={{ opacity: 0, y: 100 }}
                animate={controls}
                transition={{ delay: 2.1, duration: 1 }}
              >
                {loading ? "Enviando..." : "Enviar"}
              </motion.button>
              {sent && (
                <p className="text-white text-lg">
                  ¡Gracias! Te escribiremos a la brevedad.
                </p>
              )}
            </div>
          </motion.form>
        </div>
      </motion.div>
    </div>
  );
};

export default ContactUs;
