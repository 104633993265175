// src/app.js
import React from 'react';
import './styles/tailwind.css';
import Header from './components/Header';
import Cobros from './components/Cobros';
import HowTo from './components/HowTo';
import Benefits from './components/Benefits';
import Contact from './components/Contact';
import Questions from './components/Questions'
import Footer from './components/Footer';
import './styles/App.module.css';
import "@fortawesome/fontawesome-free/css/all.min.css";


const App = () => {
    return (
        <div>
            <Header />
            <Cobros />
            <HowTo />
            <Benefits />
            <Contact />
            <Questions />
            <Footer />
        </div>
    );
};

export default App;
