import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ReactComponent as ArrowLarge } from "../assets/arrowLarge.svg";
import { ReactComponent as MiscVertical1 } from "../assets/miscVertical1.svg";
import { ReactComponent as ArrowSmall } from "../assets/arrowSmall.svg";
import { ReactComponent as MiscVertical2 } from "../assets/miscVertical2.svg";
import { ReactComponent as FlagIcon } from "../assets/flagIcon.svg";
import { ReactComponent as GearIcon } from "../assets/gearIcon.svg";
import { ReactComponent as LockIcon } from "../assets/lockIcon.svg";

const Beneficios = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    /* 
      Contenedor principal con max-w-[1648px], centrado (mx-auto)
      y w-full para adaptarse en pantallas pequeñas sin overflow.
    */
    <div
      ref={ref}
      className={`
        text-white
        xl-large:mb-[191px]
        xl-small:mb-[190px]
        mobile-base:mb-[53px]
        xl-small:mt-[190px]
        mobile-base:mt-[120px]

        /* Nuevo: ancho máximo y centrado */
        max-w-[1648px]
        w-full
        mx-auto

        /* Mantiene los paddings horizontales que tenías */
        px-4 md:px-[2rem]
      `}
    >
      <div
        className="
          xl-small:px-[136px]
          flex flex-col md:flex-row lg:flex-row
          gap-8 items-center
          justify-center
        "
      >
        {/* Contenedor para "Beneficios para tu negocio" */}
        <div className="
          xl-large:w-[471px]
          xl-small:w-[25rem]
          h-[638px]
          mobile-base:h-auto
          xl-large:mb-[95px]
          xl-small:mb-[105px]
          mobile-base:mb-[35px]
          flex-shrink-0
          relative
        ">
          <motion.div
            className="
              absolute
              mobile-base:top-[-51px]
              mobile-base:left-[127px]
              xl-large:top-[-88px]
              xl-large:left-[236px]
              xl-small:top-[-88px]
              xl-small:left-[236px]
              transform
              -translate-x-[10%]
              -translate-y-[10%]
            "
            initial="hidden"
            animate={controls}
            variants={variants}
            transition={{ delay: 1.6, duration: 1, ease: "easeOut" }}
          >
            <ArrowLarge
              alt="Flecha grande"
              className="
                xl-large:w-full
                xl-large:h-auto
                xl-small:w-full
                xl-small:h-auto
                mobile-base:w-[89.79px]
                mobile-base:h-[89.98px]
              "
            />
          </motion.div>

          <motion.div
            className="
              absolute
              xl-large:top-[-50px]
              xl-small:top-[-50px]
              mobile-base:top-[-33px]
              xl-large:left-[34px]
              xl-small:left-[34px]
              mobile-base:left-[62px]
              transform
              -translate-x-[10%]
              -translate-y-[10%]
            "
            initial="hidden"
            animate={controls}
            variants={variants}
            transition={{ delay: 0.5, duration: 1 }}
          >
            <MiscVertical1
              alt="Gráfico vertical"
              className="
                xl-large:w-full
                xl-large:h-auto
                xl-small:w-full
                xl-small:h-auto
                mobile-base:w-[43.75px]
                mobile-base:h-[153.54px]
              "
            />
          </motion.div>

          <motion.div
            className="
              absolute
              mobile-base:top-[70px]
              mobile-base:left-[78px]
              xl-large:top-[310px]
              xl-large:left-[88px]
              xl-small:top-[310px]
              xl-small:left-[88px]
              transform
              -translate-x-[10%]
              -translate-y-[10%]
            "
            initial="hidden"
            animate={controls}
            variants={variants}
            transition={{ duration: 1, ease: "easeOut", delay: 1.7 }}
          >
            <ArrowSmall
              alt="Flecha pequeña"
              className="
                xl-large:w-full
                xl-large:h-auto
                xl-small:w-full
                xl-small:h-auto
                mobile-base:w-[49.6px]
                mobile-base:h-[49.71px]
              "
            />
          </motion.div>

          <motion.div
            className="
              absolute
              mobile-base:top-[0.5rem]
              mobile-base:left-[10rem]
              xl-large:top-[7.5rem]
              xl-large:left-[20rem]
              xl-small:top-[7.5rem]
              xl-small:left-[16rem]
              transform
              -translate-x-[10%]
              -translate-y-[10%]
            "
            initial="hidden"
            animate={controls}
            variants={variants}
            transition={{ delay: 1.0, duration: 1 }}
          >
            <MiscVertical2
              alt="Otro gráfico vertical"
              className="
                xl-large:w-full
                xl-large:h-auto
                xl-small:w-full
                xl-small:h-auto
                mobile-base:w-[43px]
                mobile-base:h-[153.54px]
              "
            />
          </motion.div>

          <motion.h2
            className="
              text-[#FFFFFF]
              xl-large:text-left
              xl-small:text-left
              mobile-base:text-center
              opacity-100
              text-[45px]
              xl-large:text-[105px]
              xl-small:text-[80px]
              leading-[45px]
              xl-large:leading-[105px]
              xl-small:leading-[105px]
              mb-4
              relative
              z-10
              mobile-base:text-[35px]
              mobile-base:leading-[35px]
            "
            initial="hidden"
            animate={controls}
            variants={variants}
            transition={{ delay: 0.4, duration: 1, ease: "easeOut" }}
          >
            <span
              className="font-normal"
              style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
            >
              Beneficios
            </span>
            <br />
            <span
              className="font-bold"
              style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
            >
              para tu negocio
            </span>
          </motion.h2>
        </div>

        {/* Contenedor de los beneficios */}
        <div
          className="
            flex flex-col gap-6
            ml-0
            xl-small:ml-[68px]
            xl-large:w-[50rem]
            xl-small:w-[40rem]
          "
        >
          {/* Beneficio 1 */}
          <motion.div
            id="benefits"
            className="
              xl-large:pl-[48px]
              xl-small:pl-[48px]
              xl-large:pr-[48px]
              xl-large:pt-[32px]
              mobile-base:pl-[14px]
              mobile-base:pr-[14px]
              mobile-base:pt-[13px]
              rounded-[40px]
              mobile-base:rounded-[16px]
              xl-large:h-[288px]
              md:w-[100%]
              xl-small:h-[288px]
              xl-large:w-[50rem]
              xl-small:w-[40rem]
              mobile-base:h-[112px]
            "
            style={{
              background:
                "linear-gradient(180deg, #122F30 2.19%, rgba(18, 47, 48, 0.85) 21.07%, rgba(18, 47, 48, 0.43) 48.53%, rgba(18, 47, 48, 0) 69.98%)",
            }}
            initial="hidden"
            animate={controls}
            variants={variants}
            transition={{ delay: 0.4, duration: 1, ease: "easeOut" }}
          >
            <motion.div
              className="
                flex items-center justify-center
                w-[75px]
                h-[75px]
                mobile-base:w-[33px]
                mobile-base:h-[33px]
                mb-4
                mobile-base:mb-[3.25rem]
                relative
                xl-small:top-[-70px]
                xl-small:w-[95px]
                xl-small:h-[95px]
                mobile-base:mt-[-2rem]
                xl-large:mt-[-1rem]
                xl-large:mb-[-3.5rem]
                xl-small:mt-[0.5rem]
                xl-small:mb-[-2.75rem]
              "
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ delay: 0.4, duration: 1, ease: "easeOut" }}
              >
              <FlagIcon alt="Icono de bandera" />
            </motion.div>
            <motion.div
              className="flex items-center mt-[-40px]"
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ delay: 1, duration: 1 }}
            >
              <motion.h3
                className="
                  xl-large:text-[32px]
                  xl-small:text-[30px]
                  mobile-base:text-[16px]
                  mobile-base:leading-[16px]
                  font-bold
                  text-white
                  opacity-100
                  xl-large:leading-[36px]
                  xl-small:leading-[36px]
                  mr-[3rem]
                  xl-small:mr-[5rem]
                "
                style={{ fontFamily: "'Blatant Bold', sans-serif" }}
                initial="hidden"
                animate={controls}
                variants={variants}
                transition={{ delay: 0.4, duration: 1, ease: "easeOut" }}
                >
                ACEPTÁ PAGOS PIX DE TUS CLIENTES DE BRASIL
              </motion.h3>

              <motion.p
                className="
                  text-white
                  opacity-100
                  xl-large:text-[28px]
                  xl-small:text-[22px]
                  xl-large:leading-[35px]
                  xl-small:leading-[35px]
                  mobile-base:text-[14px]
                  mobile-base:leading-[14px]
                "
                initial="hidden"
                animate={controls}
                variants={variants}
                transition={{ delay: 1, duration: 1 }}
              >
                <span
                  className="font-normal"
                  style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
                >
                  Ellos pagan en reales
                </span>
                <span
                  className="font-bold"
                  style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
                >
                  {" "}
                  vos recibís USDT
                </span>
              </motion.p>
            </motion.div>
          </motion.div>

          {/* Contenedor para beneficios 2 y 3 */}
          <div
            className="
              xl-small:flex-row
              mobile-base:gap-[11px]
              xl-large:gap-8
              xl-small:gap-8
              mobile-base:flex
              flex-row
            "
          >
            {/* Beneficio 2 */}
            <motion.div
              className="
                xl-large:px-[48px]
                mobile-base:px-[14px]
                xl-small:px-[48px]
                mobile-base:py-[13px]
                xl-large:pt-[79px]
                xl-large:pb-[29px]
                rounded-[40px]
                mobile-base:rounded-[16px]
                mobile-base:w-auto
                xl-small:w-[466px]
                xl-small:h-[440px]
                mobile-base:h-[170px]
                xl-large:h-[440px]
              "
              style={{
                background:
                  "linear-gradient(180deg, #122F30 2.19%, rgba(18, 47, 48, 0.85) 21.07%, rgba(18, 47, 48, 0.43) 48.53%, rgba(18, 47, 48, 0) 69.98%)",
              }}
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ delay: 0.4, duration: 1, ease: "easeOut" }}
              >
              <motion.div
                className="
                  flex items-center justify-center
                  w-[75px]
                  h-[75px]
                  mb-4
                  relative
                  xl-small:top-[-70px]
                  xl-small:w-[95px]
                  xl-small:h-[95px]
                  mobile-base:w-[33px]
                  mobile-base:h-[33px]
                  mb-4
                  mobile-base:mb-[3.5rem]
                  xl-large:mb-[0.25rem]
                  xl-large:mt-[-3.5rem]
                  xl-small:mb-[0.25rem]
                  xl-small:mt-[0.5rem]
                  mobile-base:mt-[-2rem]
                  xl-large:mb-[-1rem]
                  xl-small:mb-[-1rem]
                "
                initial="hidden"
                animate={controls}
                variants={variants}
                transition={{ delay: 0.4, duration: 1, ease: "easeOut" }}
                >
                <GearIcon alt="Icono de engranaje" />
              </motion.div>

              <motion.h3
                className="
                  xl-large:text-[32px]
                  xl-small:text-[30px]
                  mobile-base:text-[16px]
                  mobile-base:leading-[16px]
                  font-bold
                  text-white
                  opacity-100
                  xl-large:leading-[36px]
                  xl-small:leading-[36px]
                  mt-[-40px]
                  mb-[16px]
                "
                style={{ fontFamily: "'Blatant Bold', sans-serif" }}
                initial="hidden"
                animate={controls}
                variants={variants}
                transition={{ delay: 1, duration: 1 }}
              >
                SIN COMISIONES NI COSTOS DE MANTENIMIENTO
              </motion.h3>

              <motion.p
                className="
                  text-white
                  opacity-100
                  xl-large:text-[28px]
                  xl-small:text-[22px]
                  xl-large:leading-[35px]
                  xl-small:leading-[30px]
                  mobile-base:text-[14px]
                  mobile-base:leading-[14px]
                "
                initial="hidden"
                animate={controls}
                variants={variants}
                transition={{ delay: 1.5, duration: 1 }}
              >
                <span
                  className="font-normal"
                  style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
                >
                  Somos aliados en el{" "}
                </span>
                <br />
                <span
                  className="font-bold"
                  style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
                >
                  {" "}
                  crecimiento de tu negocio{" "}
                </span>
              </motion.p>
            </motion.div>

            {/* Beneficio 3 */}
            <motion.div
              className="
                xl-large:px-[48px]
                mobile-base:px-[14px]
                xl-small:px-[48px]
                xl-large:py-[29px]
                mobile-base:py-[13px]
                rounded-[40px]
                mobile-base:rounded-[16px]
                mobile-base:w-auto
                xl-small:w-[466px]
                xl-small:h-[440px]
                mobile-base:h-[170px]
                xl-large:h-[440px]
              "
              style={{
                background:
                  "linear-gradient(180deg, #122F30 2.19%, rgba(18, 47, 48, 0.85) 21.07%, rgba(18, 47, 48, 0.43) 48.53%, rgba(18, 47, 48, 0) 69.98%)",
              }}
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ delay: 0.4, duration: 1, ease: "easeOut" }}
              >
              <motion.div
                className="
                  flex items-center justify-center
                  w-[75px]
                  h-[75px]
                  mb-4
                  relative
                  xl-small:top-[-70px]
                  xl-small:w-[95px]
                  xl-small:h-[95px]
                  mobile-base:w-[33px]
                  mobile-base:h-[33px]
                  mb-4
                  mobile-base:mb-[3.5rem]
                  xl-large:mb-[0.25rem]
                  xl-small:mb-[0.25rem]
                  xl-large:mt-[-0.5rem]
                  xl-small:mt-[0.5rem]
                  mobile-base:mt-[-2rem]
                  xl-large:mb-[-1rem]
                  xl-small:mb-[-1rem]
                "
                initial="hidden"
                animate={controls}
                variants={variants}
                transition={{ delay: 0.4, duration: 1, ease: "easeOut" }}
                >
                <LockIcon alt="Icono de candado" />
              </motion.div>

              <motion.h3
                className="
                  xl-large:text-[32px]
                  xl-small:text-[32px]
                  mobile-base:text-[16px]
                  mobile-base:leading-[16px]
                  font-bold
                  text-white
                  opacity-100
                  xl-large:leading-[36px]
                  xl-small:leading-[36px]
                  mt-[-40px]
                  mb-[16px]
                "
                style={{ fontFamily: "'Blatant Bold', sans-serif" }}
                initial="hidden"
                animate={controls}
                variants={variants}
                transition={{ delay: 1, duration: 1 }}
              >
                PROTECCION TOTAL PARA CADA TRANSACCION
              </motion.h3>

              <motion.p
                className="
                  text-white
                  opacity-100
                  xl-large:text-[28px]
                  xl-small:text-[22px]
                  xl-large:leading-[35px]
                  xl-small:leading-[30px]
                  mobile-base:text-[14px]
                  mobile-base:leading-[14px]
                "
                initial="hidden"
                animate={controls}
                variants={variants}
                transition={{ delay: 1.5, duration: 1 }}
              >
                <span
                  className="font-bold"
                  style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
                >
                  Transparencia y seguridad
                </span>
                <span
                  className="font-normal"
                  style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
                >
                  {" "}
                  gracias a la tecnología blockchain.
                </span>
                <br />
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Beneficios;
