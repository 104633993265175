import React from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";

const Footer = () => {
  return (
    <>
      <style>
        {`
          .footer-content {
            display: flex;
            flex-direction: column;
            padding-left: 0.09375rem;
            padding-right: 0.09375rem;
            padding-top: 3.375rem;
            padding-bottom: 6.625rem;
          }
          .footer-content h3 {
            font-size: 1.5rem;
            font-weight: bold;
            font-family: 'Duplet Rounded', sans-serif;
            opacity: 1;
            padding-bottom: 1.75rem;
          }
          .footer-content ul {
            padding-left: 0;
          }
          .footer-content ul li {
            margin-bottom: 0.25rem;
          }
          .footer-content .social-icons {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 1rem;
          }
          .footer-content .social-icons a {
            text-decoration: none;
            border: 0.125rem solid #28ad7e;
            border-radius: 50%;
            padding: 0.5rem;
            width: 44px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color 0.3s ease;
          }
          .footer-content .social-icons a:hover {
            background-color: #28AD7E;
          }
          .footer-content .social-icons i {
            font-size: 1.125rem;
            color: white;
          }

          /* Estilos para el breakpoint xl-small */
          @media (min-width: 1366px) {
            .footer-content {
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: 2rem;
              padding-left: 7.4375rem;
            }
          }

          /* Estilos para >=48rem (768px), etc. (nativo + custom) */
          @media (min-width: 48rem) {
            .footer-content {
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: 2rem;
              padding-left: 11.4375rem;
              padding-right: 1.4375rem;
            }
            .footer-content h3 {
              font-size: 1.5rem;
            }
            .footer-content ul li {
              font-size: 1.125rem;
            }
            .footer-content .social-icons {
              justify-content: flex-start;
            }
          }

          /* <=48rem */
          @media (max-width: 48rem) {
            .logo-container {
              display: flex;
              justify-content: left;
              margin-bottom: 0.5rem;
            }
            .footer-content {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
              gap: 1rem;
              padding-left: 20px;
              padding-right: 20px;
              text-align: left;
              justify-content: center;
              padding-top: 20px;
            }
            .footer-content .social-icons {
              justify-content: center;
              margin-top: 1rem;
              grid-column: span 1;
              align-items: flex-start;
            }
            .footer-content h3 {
              font-size: 1rem;
              padding-bottom: 1rem;
            }
            .footer-content ul li {
              font-size: 0.9rem;
            }
            .footer-content .social-icons a {
              width: 2.25rem;
              height: 2.25rem;
            }
            .footer-content > .logo-container {
              grid-column: span 2;
            }
          }

          @media (min-width: 768px) and (max-width: 768px) {
            .footer-content > .logo-container {
              grid-column: span 4;
            }
          }
        `}
      </style>

      <footer className="relative text-white py-6">
        <div
          className="absolute inset-0"
          style={{
            background: "#122F30",
            mixBlendMode: "multiply",
            zIndex: 0,
          }}
        ></div>

        {/* Contenedor principal centrado + max-w */}
        <div
          className={`
            relative z-10
            footer-content
            max-w-[1648px]
            w-full
            mx-auto
            px-6 sm:px-10 md:px-12 lg:px-16
          `}
        >
          <div className="logo-container">
            <Logo className="w-[7rem] h-[2rem]" />
          </div>

          <div className="mb-8 md:mb-0">
            <h3 className="text-white text-2xl font-bold font-[Duplet Rounded] opacity-100 pb-[1.75rem]">
              Sobre nosotros
            </h3>
            <ul className="space-y-1">
              <li>
                <p className="text-base md:text-2xl">Empresa</p>
              </li>
              <li>
                <p className="text-base md:text-2xl">Preguntas frecuentes</p>
              </li>
              <li>
                <p className="text-base md:text-2xl">UVIX SAS</p>
              </li>
            </ul>
          </div>

          <div className="mb-8 md:mb-0">
            <h3 className="text-white text-2xl font-bold font-[Duplet Rounded] opacity-100 pb-[1.75rem]">
              Más información
            </h3>
            <ul className="space-y-1">
              <li>
                <p className="text-white text-base md:text-2xl">
                  Términos y condiciones
                </p>
              </li>
              <li>
                <p className="text-base md:text-2xl">Legales</p>
              </li>
            </ul>
          </div>

          <div className="mb-8 md:mb-0">
            <h3 className="text-white text-2xl font-bold font-[Duplet Rounded] opacity-100 pb-[1.75rem]">
              Contacto
            </h3>
            <ul className="space-y-1">
              <li>
                <a href="mailto:info@uvix.io" className="text-base md:text-2xl">
                  info@uvix.io
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/5492964526133"
                  className="text-base md:text-2xl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +54 9 296 452‑6133
                </a>
              </li>
            </ul>
          </div>

          <div className="social-icons">
            <a
              href="https://www.instagram.com/uvix.io/"
              aria-label="Visitar el perfil de Instagram de UVIX"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/uvix-io/"
              aria-label="Visitar la página de LinkedIn de UVIX"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.youtube.com/@uvix-io"
              aria-label="Visitar el canal de YouTube de UVIX"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
