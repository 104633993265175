import React, { useState, useEffect } from "react";
import { ReactComponent as LogoHeader } from "../assets/logoHeader.svg";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Chequea si la pantalla es <= 768px
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", checkMobile);
    checkMobile();
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  // Clases para mobile vs. desktop (fondo y posición)
  const getHeaderClass = () => {
    if (isMobile) {
      return `
        fixed bottom-0 left-0
        w-full z-50 h-auto
        bg-gradient-to-t from-[#000000FF] to-[#00000000]
        backdrop-blur-[20px]
      `;
    }
    return `
      fixed top-0 left-0
      w-full z-50
      h-[156px]
      bg-gradient-to-b from-[#000000FF] to-[#00000000]
      backdrop-blur-[20px]
      pt-10
    `;
  };

  return (
    // 1) Contenedor fijo que cubre 100% del ancho de la ventana
    <header
      className={`
        transition-all duration-300 ease-in-out
        ${getHeaderClass()}
      `}
    >
      {/* 2) Contenedor interno con max-w y centrado */}
      <div
        className={`
          xl-small:max-w-[1348px]
          xl-large:max-w-[1648px]
          w-full
          mx-auto
          px-6
          sm:px-[50px]
          md:px-[50px]
          xl-small:px-[50px]
          xl-large:px-[1rem]
        `}
      >
        {/* ============== Versión de escritorio ============== */}
        {!isMobile && (
          <nav
            className="
              flex flex-col
              mobile-base:flex-row
              mobile-base:justify-between
              items-center
            "
          >
            {/* Logo SVG */}
            <div className="text-white">
              <LogoHeader className="w-[13.6875rem] h-[5.875rem]" />
            </div>

            {/* Botonera */}
            <div className="flex flex-wrap items-center space-x-9 text-white mt-4 mobile-base:mt-0">
              <a
                href="#how-to"
                className="
                  font-normal
                  text-[18px]
                  sm:text-[20px]
                  md:text-[22px]
                  xl-small:text-[24px]
                  xl-large:text-[26px]
                  leading-[33px]
                  text-center
                  text-white
                  opacity-80
                  hover:font-bold
                  hover:!opacity-100
                  hover:!text-[#FFFFFF]
                "
              >
                ¿Cómo funciona?
              </a>

              <a
                href="#benefits"
                className="
                  font-normal
                  text-[18px]
                  sm:text-[20px]
                  md:text-[22px]
                  xl-small:text-[24px]
                  xl-large:text-[26px]
                  leading-[33px]
                  text-center
                  text-white
                  opacity-80
                  hover:font-bold
                  hover:!opacity-100
                  hover:!text-[#FFFFFF]
                "
              >
                Beneficios
              </a>

              <a
                href="#contact"
                className="
                  font-normal
                  text-[18px]
                  sm:text-[20px]
                  md:text-[22px]
                  xl-small:text-[24px]
                  xl-large:text-[26px]
                  leading-[33px]
                  text-center
                  text-white
                  opacity-80
                  hover:font-bold
                  hover:!opacity-100
                  hover:!text-[#FFFFFF]
                "
              >
                Contacto
              </a>

              {/* Botón Regístrate */}
              <a
                href="https://app.uvix.io/crear"
                className="
                  bg-[#7A4AFF]
                  text-white
                  font-bold
                  text-[18px]
                  sm:text-[20px]
                  md:text-[22px]
                  xl-small:text-[24px]
                  xl-large:text-[26px]
                  h-[51px] w-[186px]
                  rounded-full
                  hover:bg-[#4B2AA7]
                  inline-flex
                  items-center
                  justify-center
                "
              >
                Regístrate
              </a>

              {/* Botón Ingresar */}
              <a
                href="https://app.uvix.io/entrar"
                className="
                  bg-[#FFFFFF]
                  bg-opacity-25
                  border-2
                  border-[#FFFFFF]
                  text-[#FFFFFF]
                  font-bold
                  text-[18px]
                  sm:text-[20px]
                  md:text-[22px]
                  xl-small:text-[24px]
                  xl-large:text-[26px]
                  h-[51px] w-[186px]
                  rounded-full
                  hover:bg-opacity-0
                  inline-flex
                  items-center
                  justify-center
                "
              >
                Ingresar
              </a>
            </div>
          </nav>
        )}

        {/* ============== Versión móvil ============== */}
        {isMobile && (
          <nav className="flex flex-col items-center space-y-6 py-4 w-full">
            <div className="flex flex-col items-center space-y-4 text-white">
              {/* Botón Ingresar */}
              <a
                href="https://app.uvix.io/entrar"
                className="
                  bg-[#FFFFFF]
                  bg-opacity-25
                  border-2
                  border-[#FFFFFF]
                  text-[#FFFFFF]
                  font-bold
                  text-[16px]
                  mobile-base:text-[18px]
                  sm:text-[20px]
                  md:text-[22px]
                  xl-small:text-[24px]
                  h-[45px]
                  w-[335px]
                  sm:w-[200px]
                  rounded-full
                  hover:bg-opacity-0
                  inline-flex
                  items-center
                  justify-center
                "
              >
                Ingresar
              </a>

              {/* Botón Regístrate */}
              <a
                href="https://app.uvix.io/crear"
                className="
                  bg-[#7A4AFF]
                  text-white
                  font-bold
                  text-[16px]
                  mobile-base:text-[18px]
                  sm:text-[20px]
                  md:text-[22px]
                  xl-small:text-[24px]
                  h-[45px]
                  w-[335px]
                  sm:w-[200px]
                  rounded-full
                  hover:bg-[#4B2AA7]
                  inline-flex
                  items-center
                  justify-center
                "
              >
                Regístrate
              </a>
            </div>
          </nav>
        )}

        {/* ============== Línea de separación (solo en pantallas grandes) ============== */}
        <div
          className="
            max-w-7xl mx-auto
            hidden
            xl-small:block    /* >=1366px */
            xl-large:block    /* >=1920px */
            xl-small:ml-[25%]
            xl-large:ml-[40%]
          "
        >
          <svg
            width="569"
            height="1"
            viewBox="0 0 569 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 0.5H21.5H37.5H53.5H569"
              stroke="url(#paint0_linear_204_153)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_204_153"
                x1="0.5"
                y1="1"
                x2="569"
                y2="1"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#28AD7E" />
                <stop offset="1" stopColor="#122F30" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </header>
  );
};

export default Header;
