import React from "react";
import { motion } from "framer-motion";
import phoneImage from "../assets/phone-img.png";
import phoneMobile from "../assets/phone-mobile.png";
import { ReactComponent as MiscHorizontal1 } from "../assets/miscHorizontal1.svg";
import { ReactComponent as MiscHorizontal2 } from "../assets/miscHorizontal2.svg";
import { ReactComponent as LogoHeader } from "../assets/logoMobile.svg";

/* Componente para textos animados */
const DynamicText = ({ texts, wait = 2000, waitbt = 100, speed = 100, className }) => {
  const [state, setState] = React.useState({
    currentText: texts[0],
    displayedText: "",
    index: 0,
    deleting: false,
  });

  React.useEffect(() => {
    if (!state.deleting && state.index < state.currentText.length) {
      const timer = setTimeout(() => {
        setState((prev) => ({
          ...prev,
          displayedText: prev.displayedText + prev.currentText[prev.index],
          index: prev.index + 1,
        }));
      }, speed);
      return () => clearTimeout(timer);
    } else if (state.deleting && state.index > 0) {
      const timer = setTimeout(() => {
        setState((prev) => ({
          ...prev,
          displayedText: prev.displayedText.slice(0, -1),
          index: prev.index - 1,
        }));
      }, speed);
      return () => clearTimeout(timer);
    } else if (!state.deleting && state.index === state.currentText.length) {
      const timer = setTimeout(() => {
        setState((prev) => ({
          ...prev,
          deleting: true,
        })); 
      }, wait);
      return () => clearTimeout(timer);
    } else if (state.deleting && state.index === 0) {
      setState((prev) => ({
        ...prev,
        deleting: false,
        currentText: texts[(texts.indexOf(prev.currentText) + 1) % texts.length],
      }));
    }
  }, [state.index, state.currentText, state.deleting, speed, wait, texts]);

  return <span className={className}>{state.displayedText}</span>;
};

const Cobros = () => {
  return (
    <div
      className={`
        flex flex-col justify-center items-center
        w-full
        px-4
        mobile-base:px-[20px]
        sm:px-[6.5rem]
        xl-large:px-0
        xl-small:max-w-[1348px]
        xl-large:max-w-[1648px]
        xl-small:w-[95%]
        xl-large:w-[95%]
        mobile-base:w-full
        mx-auto
        lg:mt-[15rem]
        xl-large:mt-[15rem]
      `}
    >
      {/* Logo móvil visible en breakpoints chicos */}
      <div className="text-white flex flex-col mobile-base:block xl-large:hidden xl-small:hidden">
        <LogoHeader className="w-auto h-auto mb-[22px] mt-[62px]" />
      </div>

      {/* Contenedor con fondo semitransparente */}
      <div
        className="
          w-full
          mx-auto
          xl-large:h-auto
          xl-small:h-auto
          bg-white bg-opacity-20
          xl-large:rounded-[2.5rem]
          xl-small:rounded-[2.5rem]
          mobile-base:rounded-[13.89px]
          flex flex-col
          xl-large:flex-row
          xl-small:flex-row
          mobile-base:flex-col
          lg:px-[6.5rem]
          md:px-[6.5rem]
          xl-small:px-[4.5rem]
          xl-large:px-[8.875rem]
          xl-large:pt-[9.25rem]
          xl-large:pb-[9.25rem]
          xl-small:pt-[5.25rem]
          xl-small:pb-[7.25rem]
          mobile-base:pt-[40px]
          mobile-base:pb-6
          mobile-base:h-[407.8px]
        "
      >
        <div
          className="
            text-white
            flex flex-col
            xl-large:flex-row
            xl-small:flex-row
            mobile-base:flex-col
            items-center
            w-full
          "
        >
          {/* Columna Izquierda: Títulos + DynamicText */}
          <div
            className="
              mobile-base:px-[10px]
              xl-small:w-[52%]
              xl-small:text-left
              mb-8
              xl-small:mb-4
              xl-large:w-[52%]
              xl-large:text-left
              xl-large:mb-0
              mobile-base:text-center
              mobile-base:mb-[40px]
            "
          >
            <motion.h2
              className="
                font-bold
                leading-[5.4375rem]
                text-4xl
                sm:text-5xl
                xl-small:text-[3.5rem]
                xl-small:leading-[3rem]
                xl-large:text-[5.3125rem]
                xl-large:leading-[5rem]
                mobile-base:text-[35px]
                mobile-base:leading-[30px]
              "
              style={{ fontFamily: "'Blatant Bold', sans-serif" }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeOut" }}
            >
              TU SOLUCIÓN
            </motion.h2>
            <motion.h2
              className="
                font-bold
                leading-[5.4375rem]
                text-4xl
                sm:text-5xl
                xl-small:text-[3.5rem]
                xl-small:leading-[3rem]
                xl-large:text-[5.3125rem]
                xl-large:leading-[5rem]
                mobile-base:text-[35px]
                mobile-base:leading-[30px]
              "
              style={{ fontFamily: "'Blatant Bold', sans-serif" }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeOut", delay: 0.5 }}
            >
              PARA COBROS
            </motion.h2>
            <motion.h3
              className="
                font-bold
                leading-[5.4375rem]
                text-4xl
                sm:text-5xl
                xl-small:text-[3.5rem]
                xl-small:leading-[3rem]
                xl-large:text-[5.3125rem]
                xl-large:leading-[5rem]
                mobile-base:text-[35px]
                mobile-base:leading-[30px]
              "
              style={{ fontFamily: "'Blatant Bold', sans-serif" }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeOut", delay: 1 }}
            >
              INTERNACIONALES
            </motion.h3>

            <motion.div
              className="
                text-[#FFFFFF]
                font-normal
                xl-large:text-[3.25rem]
                leading-[4.1875rem]
                xl-small:text-[2rem]
                xl-small:leading-[2.5rem]
                mt-4
                font-duplet
                xl-large:leading-[4rem]
                mobile-base:text-[22px]
                mobile-base:leading-[26px]
              "
              style={{ fontFamily: "'Duplet Rounded Regular', sans-serif" }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeOut", delay: 1.5 }}
            >
              <p>
                Recibí pagos de tus clientes <br />
                de Brasil{" "}
                <DynamicText
                  className="
                    bg-[#FFFFFF]
                    text-[#28AD7E]
                    font-semibold
                    font-duplet
                    px-[0.5rem]
                    py-[0.0625rem]
                    xl-small:px-[1.1rem]
                    xl-small:py-[0.05rem]
                    rounded-full
                    opacity-100
                    h-[4.125rem]
                    w-[2.53125rem]
                    xl-small:h-[4rem]
                    xl-small:w-[25rem]
                    mobile-base:h-[3rem]
                    mobile-base:w-[20rem]
                  "
                  texts={["sin comisiones", "de forma rápida", "con total seguridad"]}
                  wait={2000}
                  waitbt={50}
                  speed={30}
                />
              </p>
            </motion.div>
          </div>

          {/* Columna Derecha: Imágenes */}
          <div
            className="
              xl-small:w-[50%]
              xl-large:w-[52%]
              relative
              flex
              justify-center
              mt-[0.5rem]
              xl-small:mt-[1rem]
              xl-large:mt-0
              mobile-base:w-full
            "
          >
            {/* Imagen desktop (mantiene posición original) */}
            <motion.img
              src={phoneImage}
              alt="Imagen del teléfono móvil con diseño moderno"
              className={`
                absolute
                max-w-full
                xl-large:h-auto
                xl-small:h-auto
                
                /* Puntos de posición originales */
                xl-small:left-[5rem]
                xl-small:top-[-20rem]
                xl-large:left-[5rem]
                xl-large:top-[-30rem]
                mobile-base:left-[2rem]
                mobile-base:top-[-3.5rem]

               
                /* mobile-base:h-[15rem]  <-- Eliminado */
                /* mobile-base:hidden     <-- Eliminado para verlo en pantallas chicas */

                xl-large:block
                xl-small:block
                
                w-[15rem]       /* base */
                sm:w-[20rem]    /* >=640px */
                md:w-[25rem]    /* >=768px */
                xl-small:w-[28rem] /* en vez de 40rem, más moderado */
                xl-large:w-[40rem]  /* si quieres que crezca más en 1920px+ */
                h-auto
              `}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeOut" }}
            />

            {/* Imagen mobile */}
            <motion.img
              src={phoneMobile}
              alt="Imagen del teléfono móvil en vista frontal"
              className="
                flex
                flex-col
                justify-center
                w-[15rem]
                h-auto
                xl-large:hidden
                xl-small:hidden
              "
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeOut", delay: 1.8 }}
            />

            {/* MiscHorizontal1 */}
            <motion.div
              className="
                absolute
                max-w-full
                h-auto

                /* Posiciones originales */
                xl-small:left-[14rem]
                xl-small:top-[-12rem]
                xl-large:left-[22rem]
                xl-large:top-[-18.3rem]
                mobile-base:left-[2rem]
                mobile-base:top-[-3.5rem]
                mobile-base:w-[15rem]
                xl-large:block
                xl-small:block
                mobile-base:hidden
              "
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, ease: "easeOut", delay: 1 }}
            >
              <MiscHorizontal1
                className="
                  h-auto
                  /* Ajustes de ancho para breakpoints,
                     sin cambiar la posicion original 
                  */
                  w-[10rem]
                  sm:w-[14rem]
                  md:w-[18rem]
                  lg:w-[22rem]
                  xl-small:w-[20rem]
                  xl-large:w-[461px]
                "
              />
            </motion.div>

            {/* MiscHorizontal2 */}
            <motion.div
              className="
                absolute
                max-w-full
                h-auto

                /* Posiciones originales */
                xl-small:left-[0.5rem]
                xl-small:top-[7rem]
                xl-large:left-[0.3rem]
                xl-large:top-[10rem]
                mobile-base:left-[0rem]
                mobile-base:top-[4rem]
                xl-large:block
                xl-small:block
                mobile-base:hidden
              "
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, ease: "easeOut", delay: 1 }}
            >
              <MiscHorizontal2
                className="
                  h-auto
                  /* Ajustes de ancho para breakpoints */
                  w-[8rem]
                  sm:w-[12rem]
                  md:w-[16rem]
                  lg:w-[20rem]
                  xl-small:w-[18rem]
                  xl-large:w-[375px]
                "
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cobros;
