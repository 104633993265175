import React from "react";

const Questions = () => {
  const faqs = [
    {
      question: "¿Qué puedo hacer con Uvix desde Argentina?",
      answer: [
        "Ofrecemos soluciones simples y seguras para tus transacciones internacionales. Con Uvix podés:",
        "Cobrar reales mediante Pix y recibir USDT.",
        "Enviar reales mediante Pix.",
        "Enviar y recibir USDT.",
        "Fondear tu cuenta con pesos y recibir USDT."
      ],
      isParagraph: true
    },
    {
      question: "¿Qué beneficios ofrecemos?",
      answer: [
        "Brindamos una serie de beneficios que facilitan cada transacción, entre ellos podrás disfrutar de:",
        "Operar sin comisiones.",
        "Administrar tus ingresos en una billetera segura.",
        "Utilizar tus fondos al instante.",
        "Crear equipos de trabajo.",
        "Mejorar la experiencia de turistas brasileros que visitan Argentina."
      ],
      isParagraph: true
    },
    {
      question: "¿Quiénes pueden usar Uvix?",
      answer: [
        "Uvix está diseñado tanto para personas físicas, es decir, usuarios individuales que necesitan realizar cobros y envíos de dinero internacionalmente, como para personas jurídicas. Negocios que gestionan pagos a través de transacciones internacionales."
      ],
      isParagraph: true
    },
    {
      question: "¿La cuenta tiene costos de apertura o mantenimiento?",
      answer: [
        "No, la cuenta en Uvix no tiene costos de apertura ni tarifas de mantenimiento. Podés registrar tu negocio y operar en la plataforma de forma totalmente gratuita, sin preocuparte por cargos adicionales. ¡Empezá a disfrutar de los beneficios de Uvix!"
      ],
      isParagraph: true
    },
  ];

  return (
    <section
      aria-labelledby="faq-title"
      className={`
        /* Centrar y limitar el ancho máximo */
        xl-small:max-w-[1348px]
        xl-large:max-w-[1648px]        w-full
        mx-auto

        /* Paddings laterales fluidos */
        px-6 sm:px-10 md:px-[4rem] lg:px-[8.5rem] xl-small:px-[10rem] xl-large:px-[12rem]

        /* Margen inferior escalonado */
        mb-12 md:mb-24 lg:mb-[191px]

        /* Margen top a partir de mobile-base */
        mobile-base:mt-12

        py-6 md:py-8
        text-white
      `}
    >
      <div
        className="
          p-6 sm:p-8 md:p-[2rem] lg:p-[3rem]
          border-0 md:border border-teal-800
          rounded-[2.5rem]
        "
      >
        <h2
          id="faq-title"
          style={{ fontFamily: "'Blatant Bold', sans-serif" }}
          className="
            text-white text-center font-bold
            text-[1.375rem]
            mobile-base:text-[1.625rem]
            md:text-[2.5rem]
            lg:text-[3.25rem]
            leading-snug
            lg:leading-[4.375rem]
            mb-[2.5rem]
            lg:mb-[4.625rem]
          "
        >
          ¿Tenés alguna pregunta?
        </h2>

        <div className="space-y-[1.5rem]">
          {faqs.map((faq, index) => (
            <details
              key={index}
              className="bg-transparent border-b border-gray-300 pb-[0.5rem] cursor-pointer group"
            >
              <summary
                className="
                  text-white
                  flex justify-between items-center
                  font-normal
                  list-none
                  text-base
                  mobile-base:text-[1.125rem]
                  md:text-[1.25rem]
                  lg:text-[2.25rem]
                  leading-normal
                  lg:leading-[2.1875rem]
                  mb-[1rem]
                  lg:mb-[2rem]
                "
              >
                {faq.question}
                <svg
                  className="group-open:rotate-180 transform transition-transform w-[1.875rem] h-[1.875rem] flex-shrink-0"
                  width="1.875rem"
                  height="1.875rem"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    d="M10 15L20 25L30 15"
                    stroke="#EDEDED"
                    strokeWidth="0.25rem"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </summary>

              {faq.isParagraph ? (
                <>
                  <p
                    className="
                      text-gray-300
                      text-base
                      mobile-base:text-[1.125rem]
                      md:text-[1.25rem]
                      lg:text-[1.5rem]
                      mb-[0.5rem]
                    "
                  >
                    {faq.answer[0]}
                  </p>

                  <ul
                    className="
                      text-gray-300
                      list-disc
                      pl-[1.5rem]
                      space-y-[0.5rem]
                      lg:mb-[40px]
                      text-base
                      mobile-base:text-[1.125rem]
                      md:text-[1.25rem]
                      lg:text-[1.5rem]
                    "
                  >
                    {faq.answer.slice(1).map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </>
              ) : (
                <ul
                  className="
                    text-gray-300
                    list-disc
                    pl-[1.5rem]
                    space-y-[0.5rem]
                    lg:mb-[40px]
                    text-base
                    mobile-base:text-[1.125rem]
                    md:text-[1.25rem]
                    lg:text-[1.5rem]
                  "
                >
                  {faq.answer.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              )}
            </details>
          ))}
        </div>

        <div className="text-center mt-[2.5rem] lg:mt-[4.625rem]">
          <button
            className="
              bg-transparent
              border border-white
              text-white
              px-4
              md:px-6
              py-2
              rounded-full
              hover:bg-white
              hover:text-teal-900
              transition
              font-bold
              text-base
              mobile-base:text-[1.125rem]
              md:text-[1.25rem]
              lg:text-[1.625rem]
              leading-normal
              lg:leading-[2.08rem]
            "
            aria-label='Ver todas las preguntas frecuentes'
          >
            Ver todas las preguntas frecuentes
          </button>
        </div>
      </div>
    </section>
  );
};

export default Questions;
